import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import * as Ant from "antd";
import * as Theme from "../Theme";

import { Context } from "../AppContext";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Cart from "../Contexts/CartContext";
import CartHeader from "../Components/NewCart/CartHeader";
import NewCartDetail from "../Components/NewCart/NewCartDetail";
import CheckoutNew from "../Components/NewCart/CheckoutNew";
import CartCalculation from "../Components/NewCart/CartCalculation";

import formatValidator from "../Utils/formatValidator";
import * as LogisticUtil from "../Utils/LogisticUtil";
import invoiceValidator from "../Utils/invoice-validator";
import {
  ErrCoupon,
  ErrCheckoutForm,
  ErrInvoiceFormat,
  errorHandler,
} from "../errors";

const {
  CUSTOMER_CONFIGS,
  SHIPPING_CONFIGS,
  INVOICE_CONFIGS,
} = require("../CartConfigs");

const qs = require("query-string");

const constants = require("../constants");

const {
  isNotEmpty,
  isEmail,
  isMobileNumber,
  isExceedStringMaxLength,
} = formatValidator;

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function NewCartPage(props) {
  const { cart, location } = props;
  const queryParams = qs.parse(location.search);
  const [step, setStep] = useState(0);
  const [redirecting, setRedirecting] = useState(
    queryParams.step ? true : false
  );
  const app = useContext(Context);
  const { profile } = app.state;
  const [config, setConfig] = useState({
    userConfig: {
      ...CUSTOMER_CONFIGS,
    },
    deliveryConfig: {
      ...SHIPPING_CONFIGS,
    },
    invoiceConfig: {
      ...INVOICE_CONFIGS,
    },
    extraConfig: {
      use_bonus: false,
    },
  });

  let prevDeliveryType = useRef(constants.supportLogistics[0]);
  let prevUseBonus = useRef(false);

  const [params, setParams] = useState({
    payment_type: "",
    payment_subtype: "",
    coupon: "",
    note: "",
    is_share_order_photo: false,
    share_info: "",
  });

  const [InfoBoxFinished, setInfoBoxFinished] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const goToNextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  const goToPrevStep = () => {
    if (step === 0) {
      return navigate("/products");
    }
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const autofill = useCallback(() => {
    if (cart && profile) {
      const { config: _config } = cart?.data;
      //user
      let userConfig = {
        name: _config?.name || profile?.name || "",
        phone: _config?.phone || profile.phone || "",
        email: _config.email || profile.email,
        tel: _config.tel,
        tel_ext: _config.tel_ext,
        zip: _config.zip || profile.zip_code,
        city: _config.city || profile.city,
        district: _config.district || profile.district,
        address: _config.address || profile.address,
      };

      //delivery
      //not fill, default 新竹物流

      //invoice
      let invoiceConfig = config.invoiceConfig;

      //other config
      let extraConfig = {
        use_bonus: _config.use_bonus || false,
      };

      setConfig({
        userConfig,
        deliveryConfig: config.deliveryConfig,
        invoiceConfig,
        extraConfig,
      });
    }
  }, [cart?.data, profile]);

  useEffect(() => {
    if (profile && cart.data) {
      autofill();
    } else if (!profile) {
      Ant.message.warning("請先登入");
      navigate("/profile");
    }
  }, [profile, cart.data]);

  const updateConfig = useCallback(
    async (callback = () => {}) => {
      app.actions.setLoading(true);
      const { userConfig, deliveryConfig, invoiceConfig, extraConfig } = config;
      try {
        let resp = await Cart.Actions.editConfig({
          ...userConfig,
          ...extraConfig,
          deliveryConfig,
          invoiceConfig,
        });
        autofill();
        callback();
      } catch (ex) {
        errorHandler(ex);
      }
      app.actions.setLoading(false);
    },
    [app.actions, autofill, config]
  );

  // delivery_type changed, then update cart.config
  // 有使用折扣碼，則 updateConfig

  useEffect(() => {
    if (prevDeliveryType.current !== config.deliveryConfig?.delivery_type) {
      prevDeliveryType.current = config.deliveryConfig?.delivery_type;
      updateConfig();
    }
    if (prevUseBonus.current !== config.extraConfig?.use_bonus) {
      prevUseBonus.current = config.extraConfig?.use_bonus;
      updateConfig();
      setParams(prev => ({ ...prev, coupon: "" }));
    }
  }, [
    config.deliveryConfig?.delivery_type,
    config.extraConfig?.use_bonus,
    updateConfig,
  ]);

  useEffect(() => {
    // ezship redirect back to cart step 2
    if (redirecting && profile && cart.data) {
      const {
        st_state,
        st_code,
        rstore_name,
        rstore_tel,
        rstore_addr,
      } = queryParams;

      setStep(queryParams.step - 1);
      setRedirecting(false);
    }
  }, [redirecting, profile, cart.data]);

  async function createOrder() {
    try {
      if (params.payment_type === "period") {
        if (window.confirm("請選擇使用期限足夠的信用卡")) {
          let { url } = await app.actions.getPeriodCheckoutUrl();

          if (url) {
            window.location.href = url;
          }
        }
      } else {
        const order = await Cart.Actions.checkout(params);
        navigate(`/order?id=${order.id}`);
      }
    } catch (err) {
      if (err.error === "user_is_frozen") {
        err = new ErrCheckoutForm(
          "月結帳號已凍結，您的月結單繳費期限已到期，帳號已凍結限制下單，為避免因凍結會員下單權限而損失您的權益，請儘速前往繳費！謝謝"
        );
      }
      let re = /Object with code\=\w+ does not exist\./g;
      if (err.coupon && err.coupon.length > 0 && re.test(err.coupon[0])) {
        err = new ErrCoupon("此折扣代碼不存在");
      }

      if (err.error === "coupon_expired") {
        err = new ErrCoupon("此折扣代碼過期");
      }

      if (err.error === "coupon_usage_limit") {
        err = new ErrCoupon("此折扣代碼已兌換完畢");
      }

      if (err.error === "coupon_user_usage_limit") {
        err = new ErrCoupon("您的折扣代碼已使用達上限");
      }

      if (err.error === "order_coupon_error") {
        err = new ErrCoupon(`訂單金額小於此折扣代碼最低門檻`);
      }

      if (err.error === "coupon_user_group_error") {
        err = new ErrCoupon(`無法使用此折扣代碼`);
      }
      if (err.error === "user_monthly_quota_over") {
        re = /monthly_quota\:(\d+).+order_amount:(\d+).+cart_amount:(\d+)/;
        let match = typeof err.detail === "string" ? err.detail.match(re) : [];
        let rest = parseInt(match?.[1]) - parseInt(match?.[2]);
        err = new ErrCoupon(
          `當月月結額度不足！已超過當月剩餘月結額度：＄${rest} / ＄${match?.[1]}，請聯絡客服人員`
        );
      }
      if (
        err.error === "config_error" &&
        Array.isArray(err.detail) &&
        err.detail.length > 0
      ) {
        if (err.detail[0].indexOf("is required") > 0) {
          err = new ErrCheckoutForm("有欄位必填，尚未填寫");
        } else {
          err = new ErrCheckoutForm("有欄位格式錯誤");
        }
      }
      throw err;
    }
  }

  const valid = config => {
    if (!termsAgreed) {
      throw new ErrCheckoutForm("請閱讀且同意注意事項");
    }

    if (!params.payment_type) {
      throw new ErrCheckoutForm("請選擇付款方式");
    }

    const { invoiceConfig, userConfig, deliveryConfig } = config;

    // user config
    let { name, phone, city, district, address, email } = userConfig;
    if (
      !isNotEmpty(name) ||
      !isNotEmpty(city) ||
      !isNotEmpty(district) ||
      !isNotEmpty(address) ||
      !isNotEmpty(email) ||
      !isNotEmpty(phone)
    ) {
      throw new ErrCheckoutForm("請填入會員資料必填欄位");
    }
    if (!isEmail(email)) {
      throw new ErrCheckoutForm("會員資料電子信箱格式錯誤");
    }
    if (!isMobileNumber(phone)) {
      throw new ErrCheckoutForm("會員資料手機格式錯誤");
    }

    if (isExceedStringMaxLength(name)) {
      throw new ErrCheckoutForm("會員資料名稱長度過長");
    }

    if (isExceedStringMaxLength(address)) {
      throw new ErrCheckoutForm("會員資料通訊地址長度過長");
    }

    //delivery config
    let {
      delivery_type = "hct",
      is_delivery_private = "",
      receiver_name = "",
      receiver_phone = "",
      receiver_city = null,
      receiver_district = null,
      receiver_address = "",
      sender_name = "",
      sender_phone = "",
      sender_city = null,
      sender_district = null,
      sender_address = "",
    } = deliveryConfig;

    if (
      ["hct", "mailing", "tcat", "kerry_tj", "special_car"].includes(
        delivery_type
      )
    ) {
      if (
        !isNotEmpty(receiver_name) ||
        !isNotEmpty(receiver_phone) ||
        !isNotEmpty(receiver_city) ||
        !isNotEmpty(receiver_district) ||
        !isNotEmpty(receiver_address)
      ) {
        throw new ErrCheckoutForm("請填入宅配運送/收件人必填欄位");
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm("收件人手機格式錯誤");
      }
    } else if (delivery_type === "ezship") {
      if (!isNotEmpty(receiver_name) || !isNotEmpty(receiver_phone)) {
        throw new ErrCheckoutForm("請填入收件人必填欄位");
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm("收件人手機格式錯誤");
      }

      if (LogisticUtil.disabledEzship(cart.data, app.actions)) {
        let names = LogisticUtil.disabledEzshipItems(
          cart.data,
          app.actions
        ).map(x => x.name);
        throw new ErrCheckoutForm(
          `${names.join(
            ", "
          )}商品僅支援宅配，建議您選擇『 宅配 』物流付款，若仍需以超商取貨，則需分批下單恕無法合併運費計算！`
        );
      }
    } else {
      // self pick
    }

    if (is_delivery_private) {
      /* 保密代記 */
      if (
        !isNotEmpty(sender_name) ||
        !isNotEmpty(sender_phone) ||
        !isNotEmpty(sender_city) ||
        !isNotEmpty(sender_district) ||
        !isNotEmpty(sender_address)
      ) {
        throw new ErrCheckoutForm("請填入保密代寄/寄件人必填欄位");
      }
      if (!isMobileNumber(sender_phone)) {
        throw new ErrCheckoutForm("保密代寄/寄件人手機格式錯誤");
      }

      if (
        sender_name === receiver_name ||
        sender_phone === receiver_phone ||
        sender_address === receiver_address
      ) {
        throw new ErrCheckoutForm("保密代寄/寄件人資料不可和收件人資料相同");
      }

      if (isExceedStringMaxLength(sender_name)) {
        throw new ErrCheckoutForm("保密代寄/寄件人名長度過長");
      }

      if (isExceedStringMaxLength(sender_address)) {
        throw new ErrCheckoutForm("保密代寄/寄件地址長度過長");
      }
    }

    if (isExceedStringMaxLength(receiver_name)) {
      throw new ErrCheckoutForm("收件人名長度過長");
    }

    if (isExceedStringMaxLength(receiver_address)) {
      throw new ErrCheckoutForm("收件地址長度過長");
    }

    //invoice
    invoiceValidator(invoiceConfig);
  };

  const handleOnSubmitOrder = async () => {
    app.actions.setLoading(true);
    try {
      valid(config);
      // 記住資訊
      let { userConfig } = config;
      app.actions.editMyProfile({
        address: userConfig.address,
        city: userConfig.city,
        district: userConfig.district,
        name: userConfig.name,
        phone: userConfig.phone,
        zip_code: userConfig.zip,
      });
      await updateConfig();
      await createOrder();
    } catch (err) {
      console.warn(err);

      if (err instanceof ErrInvoiceFormat || err instanceof ErrCheckoutForm) {
        Ant.message.error(err.message);
      }
    }
    app.actions.setLoading(false);
  };

  if (!cart.data || redirecting) {
    return (
      <Wrapper css="display:flex;justify-content:center;align-items:center;padding-top: 100px;">
        <Ant.Spin spinning={true}></Ant.Spin>
      </Wrapper>
    );
  }

  if (cart.data.items.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: Theme.colors.blueGreen,
          padding: 20,
          height: "70vh",
        }}
      >
        <figure
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../../images/empty-cart.svg"
            width="280px"
            style={{ maxWidth: 650, marginBottom: 30 }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 16, marginBottom: 5, color: "#ffffff" }}>
              您尚未選購商品加入的購物車！
            </p>
            <p style={{ fontSize: 16, marginBottom: 5, color: "#ffffff" }}>
              歡迎瀏覽選購其他商品！
            </p>
          </div>
          <Link
            to="/products"
            style={{
              cursor: "pointer",
              marginTop: 50,
              padding: "10px 50px",
              border: "2px solid #ffffff",
              borderRadius: 3,
              color: "#fff",
            }}
          >
            前往感官線上訂購 SHOP
          </Link>
        </figure>
      </div>
    );
  }
  return (
    <Wrapper>
      <div className="page-container">
        <div className="cart-status">
          <CartHeader goToPrevStep={goToPrevStep} step={step} />
        </div>
        <CenterContent>
          {step === 0 && (
            <CartContent>
              <NewCartDetail goToPrevStep={goToPrevStep} cartData={cart.data} />
            </CartContent>
          )}
          {step === 1 && (
            <CheckoutContent>
              <CheckoutNew
                cartData={cart.data}
                config={config}
                setConfig={(type, config) => {
                  setConfig(prev => {
                    return {
                      ...prev,
                      [`${type}Config`]: {
                        ...prev[`${type}Config`],
                        ...config,
                      },
                    };
                  });
                }}
                params={params}
                setParams={obj => setParams(prev => ({ ...prev, ...obj }))}
                setInfoBoxFinished={setInfoBoxFinished}
              />
            </CheckoutContent>
          )}
          <CartCalculation
            cartDataItems={cart.data.items}
            calculations={cart.data.calculations}
            step={step}
            goToNextStep={goToNextStep}
            handleOnSubmitOrder={handleOnSubmitOrder}
            InfoBoxFinished={InfoBoxFinished}
            termsAgreed={termsAgreed}
            setTermsAgreed={setTermsAgreed}
            config={config}
            setConfig={setConfig}
            profileBonus={profile.bonus}
            params={params}
            setParams={obj => setParams(prev => ({ ...prev, ...obj }))}
          />
        </CenterContent>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #edeef1;
  align-items: center;
  display: flex;
  flex-direction: column;

  .page-container {
    max-width: 1200px;
    width: 100%;
  }

  .cart-status {
    padding: 26px 0;
    width: 100%;

    @media screen and (max-width: ${constants.breakpoints.xl}px) {
      padding: 24px 32px;
    }
  }
  .checkout-form {
    padding-top: ${Theme.rwdPadding.large};
    padding-bottom: ${Theme.rwdPadding.large};
  }

  .payment-section {
    padding-top: ${Theme.rwdPadding.large};
    padding-bottom: ${Theme.rwdPadding.large};
  }

  ${props => props.css || ""}
`;

const CenterContent = styled.div`
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 95px;

  @media screen and (max-width: ${constants.breakpoints.xl}px) {
    display: block;
  }

  @media screen and (max-width: ${constants.breakpoints.md}px) {
    padding: 0;
  }
`;

const CartContent = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;

  @media screen and (max-width: ${constants.breakpoints.xl}px) {
    width: 100%;
  }
`;

const CheckoutContent = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`;

export default Cart.withConsumer(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(NewCartPage)
);
