function disabledEzship(cart, actions, spec) {
  const { items = [] } = cart;
  if (!spec) {
    return true;
  }
  return items.some(item => {
    let spec = actions.getProductFromSpec({
      productName: item.name,
      spec: null,
    });
    return !spec["allow_ezship"];
  });
}

function disabledEzshipItems(cart, actions, spec) {
  const { items = [] } = cart;
  if (!spec) {
    return [];
  }
  return items.filter(item => {
    let spec = actions.getProductFromSpec({
      productName: item.name,
      spec: null,
    });
    return !spec["allow_ezship"];
  });
}

export { disabledEzship, disabledEzshipItems };
