import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Input, Button, Select } from "antd";

import { Expander, RowText, Block } from "../../Widgets";
import CustomerInfo from "./CustomerInfo";
import ShippingInfo from "./ShippingInfo";
import PaymentMethods from "./PaymentMethods";
import InvoiceInfo from "./InvoiceInfo";

import {
  INVOICE_TYPES,
  DELIVERY_TYPE,
  TWO_COPIES_TYPES,
  LOVECODE_OPTIONS,
  PAYMENT_TYPES,
} from "../../dictionary";

import formatValidator from "../../Utils/formatValidator";

const {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
} = formatValidator;

const constants = require("../../constants");

export default function CheckoutNew({
  cartData,
  config,
  setConfig,
  params,
  setParams,
  setInfoBoxFinished,
}) {
  const { userConfig, deliveryConfig, invoiceConfig } = config;
  const hasUserInfo = Object.values(userConfig).some(value => value);
  const hasShippingInfo = Object.values(deliveryConfig).some(value => value);
  const paymentTypeObj = Object.values(PAYMENT_TYPES).find(
    type =>
      type.value === params.payment_subtype ||
      type.value === params.payment_type
  );

  const invoiceTypeObj = Object.values(INVOICE_TYPES).find(
    type => type.value === invoiceConfig.invoice_type
  );

  const [shippingInfoExpand, setShippingInfoExpand] = useState(false);
  const [orderNoteExpand, setOrderNoteExpand] = useState(false);
  const [currentShippingType, setCurrentShippingType] = useState(
    deliveryConfig.delivery_type
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const shippTypeFee = [
    { type: "self_pick", fee: 0 },
    { type: "hct", fee: 100 },
    { type: "ezship", fee: 80 },
  ];

  // 用來計算運費
  const calculateShippingFee = type => {
    const isFreeShipping =
      cartData.calculations.fee + cartData.calculations.fee_tax === 0;
    console.log(cartData.calculations);
    const fee = shippTypeFee.find(item => item.type === type).fee;
    const feeText = isFreeShipping ? "免運" : `NT$${fee}`;
    return { fee, feeText };
  };

  const renderUserInfoBlock = () => {
    return (
      <Block
        title={"1 訂購人資訊"}
        size={`${constants.fontSize.md}px`}
        fontWeight={700}
        height={"30px"}
        lineHeight={"30px"}
        padding={"0px"}
        bg={constants.colors.background}
        color={constants.colors.text}
      >
        {hasUserInfo && (
          <InfoBox
            style={{
              padding: "0",
              backgroundColor: "transparent",
            }}
          >
            <RowText
              weight="400"
              color="#25272c"
              value={`${userConfig.name} ${userConfig.phone}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={userConfig.email}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${userConfig.city || ""}${userConfig.district ||
                ""}${userConfig.address || ""}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
              }}
            />
          </InfoBox>
        )}
      </Block>
    );
  };

  const renderShippingInfoBlock = () => {
    const deliveryType = DELIVERY_TYPE[deliveryConfig.delivery_type];

    return (
      <Block
        title={"2 運送方式"}
        size={`${constants.fontSize.md}px`}
        fontWeight={700}
        height={"30px"}
        lineHeight={"30px"}
        padding={"0px"}
        bg={constants.colors.background}
        color={constants.colors.text}
      >
        {hasShippingInfo && deliveryConfig.delivery_type === "self_pick" ? (
          <ShippingInfoContainer>
            <RowText
              weight="700"
              color="#000000"
              value={`${deliveryType} 免運`}
              containerStyle={{ margin: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
              }}
            />
            <InfoBox>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img
                  src="/images/location_on.svg"
                  alt="location"
                  style={{
                    width: "24px",
                  }}
                />
                <RowText
                  color="#25272c"
                  value={"新北市中和區板南路496號3樓"}
                  containerStyle={{ margin: 0 }}
                  style={{
                    width: "100%",
                    lineHeight: "24px",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img
                  src="/images/schedule.svg"
                  alt="schedule"
                  style={{
                    width: "24px",
                  }}
                />
                <RowText
                  color="#25272c"
                  value={
                    "星期一到五 10:00-18:00 (國定例假日休息) 請先預約取件時間"
                  }
                  containerStyle={{ margin: 0 }}
                  style={{
                    width: "100%",
                    lineHeight: "24px",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </InfoBox>
            <div>
              <div>
                印刷品完成時，本網站將會發送一封商品完成的簡訊與e-mail通知，收到通知後，
              </div>
              <div>
                請先與客服預約前來面交自取的時間，並持簡訊或e-mail通知，於預約時間前往上述自取地址取貨。
              </div>
              <ul style={{ paddingLeft: 30, paddingTop: 10 }}>
                <li>客服電話：02-2736-6566</li>
                <li>Facebook 粉絲頁：感官文化印刷 Sensations Print</li>
                <li>LINE@ ：專人LINE詢問 @ssprint</li>
                <li>Email 客服：service@ssprint.com.tw</li>
              </ul>
            </div>
          </ShippingInfoContainer>
        ) : (
          <div style={{ marginTop: "16px" }}>
            <RowText
              weight="700"
              color="#25272c"
              value={`${deliveryType} ${
                calculateShippingFee(deliveryConfig.delivery_type).feeText
              }`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
              }}
            />
            <InfoBox style={{ marginTop: "8px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img
                  src="/images/location_on.svg"
                  alt="location"
                  style={{
                    width: "24px",
                  }}
                />
                <RowText
                  weight="400"
                  color="#25272c"
                  value={
                    deliveryConfig.delivery_type === "ezship"
                      ? `${deliveryConfig.rstore_name}${deliveryConfig.rstore_addr}`
                      : `${deliveryConfig.receiver_city}${deliveryConfig.receiver_district}${deliveryConfig.receiver_address}`
                  }
                  containerStyle={{ marginBottom: 0 }}
                  style={{
                    width: "100%",
                    lineHeight: "24px",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/images/call.svg"
                  alt="call"
                  style={{
                    width: "24px",
                  }}
                />
                <RowText
                  weight="400"
                  color="#25272c"
                  value={`${deliveryConfig.receiver_name} ${deliveryConfig.receiver_phone}`}
                  containerStyle={{ marginBottom: 0 }}
                  style={{
                    width: "100%",
                    lineHeight: "24px",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </InfoBox>
          </div>
        )}
      </Block>
    );
  };

  const renderPaymentInfoBlock = () => {
    return (
      <Block
        title={"3 付款與發票"}
        size={`${constants.fontSize.md}px`}
        fontWeight={700}
        height={"30px"}
        lineHeight={"30px"}
        padding={"0px"}
        bg={constants.colors.background}
        color={constants.colors.text}
      >
        <InfoBox
          style={{
            padding: "0",
            backgroundColor: "transparent",
          }}
        >
          {renderInvoiceContent()}
        </InfoBox>
      </Block>
    );
  };

  const renderInvoiceContent = () => {
    const paymentType = paymentTypeObj && paymentTypeObj.label;
    const invoiceType = invoiceTypeObj && invoiceTypeObj.label;

    if (!paymentType || !invoiceType) {
      return null;
    }

    switch (invoiceConfig.invoice_type) {
      case "two_copies":
        return (
          <>
            <RowText
              weight="700"
              color="#25272c"
              value={`${paymentType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${invoiceType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
          </>
        );
      case "three_copies":
        return (
          <>
            <RowText
              weight="700"
              color="#25272c"
              value={`${paymentType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${invoiceType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${invoiceConfig.company_title}${invoiceConfig.gui_number}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
          </>
        );
      case "donate":
        return (
          <>
            <RowText
              weight="700"
              color="#25272c"
              value={`${paymentType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${invoiceType}`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
            <RowText
              weight="400"
              color="#25272c"
              value={`${
                LOVECODE_OPTIONS.find(
                  option => option.value === invoiceConfig.love_code
                ).label
              }`}
              containerStyle={{ marginBottom: 0 }}
              style={{
                width: "100%",
                lineHeight: "24px",
                maxWidth: "100%",
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  const renderOrderNoteBlock = () => {
    return (
      <Block
        title={"4 訂單備註"}
        size={`${constants.fontSize.md}px`}
        fontWeight={700}
        height={"30px"}
        lineHeight={"30px"}
        padding={"0px"}
        bg={constants.colors.background}
        color={constants.colors.text}
      >
        <div style={{ marginTop: "16px" }}>{params.note}</div>
      </Block>
    );
  };

  const renderEditTitle = title => {
    // title 開頭是數字，把他的數字 color 改成 highlight
    const titleArr = title.split(" ");
    const firstWord = titleArr[0];
    const rest = titleArr.slice(1).join(" ");

    return (
      <>
        <span style={{ color: constants.colors.highlight }}>{firstWord}</span>{" "}
        {rest}
      </>
    );
  };

  const checkedCurrentStep = () => {
    // 按照順序檢查是否有填寫資料
    // 回傳第一個沒有填寫的步驟
    if (!hasUserInfo) {
      return 1;
    }
    if (!hasShippingInfo) {
      return 2;
    }
    if (!paymentTypeObj || !invoiceTypeObj) {
      return 3;
    }
    return 4;
  };

  // 驗證發票資訊
  const validatePaymentAndInvoice = () => {
    if (!paymentTypeObj || !invoiceTypeObj) {
      return false;
    }

    const { invoice_type, invoice_subtype } = invoiceConfig;
    if (invoice_type === "two_copies") {
      if (invoice_subtype === "citizen_personal_certificate") {
        return (
          isPersonCertificatedCode(
            invoiceConfig.citizen_personal_certificate
          ) && isNotEmpty(invoiceConfig.citizen_personal_certificate)
        );
      } else if (invoice_subtype === "mobile_vehicle") {
        return (
          isMobileVehicleCode(invoiceConfig.mobile_vehicle_code) &&
          isNotEmpty(invoiceConfig.mobile_vehicle_code)
        );
      }
    } else if (invoice_type === "three_copies") {
      return (
        isCompanyId(invoiceConfig.gui_number) &&
        isNotEmpty(invoiceConfig.company_title)
      );
    } else if (invoice_type === "donate") {
      return (
        isLoveCode(invoiceConfig.love_code) &&
        isNotEmpty(invoiceConfig.love_code)
      );
    }
    return true;
  };

  // 一進來的時候檢查目前的步驟
  useEffect(() => {
    setCurrentStep(checkedCurrentStep());
  }, []);

  useEffect(() => {
    if (!editMode) {
      setCurrentStep(checkedCurrentStep());
    }
  }, [editMode]);

  useEffect(() => {
    if (currentStep === 4) {
      setInfoBoxFinished(true);
    } else {
      setInfoBoxFinished(false);
    }
  }, [currentStep]);

  return (
    <Fragment>
      <Row>
        <Col flex="100%">
          <Wrap>
            <Expander
              expand={currentStep === 1}
              onClick={() => {
                setCurrentStep(1);
                setEditMode(true);
              }}
              title={renderUserInfoBlock()}
              containerStyle={{ marginBottom: 0 }}
              titleStyle={{ textAlign: "center" }}
              containerMobileStyle={{ justifyContent: "center !important" }}
            >
              <Block
                title={renderEditTitle("1 訂購人資訊")}
                size={`${constants.fontSize.md}px`}
                fontWeight={700}
                height={"30px"}
                lineHeight={"30px"}
                padding={"0px"}
                bg={constants.colors.background}
                color={constants.colors.text}
                titleMargin={"0 0 16px 0"}
              >
                <CustomerInfo
                  config={userConfig}
                  setConfig={setConfig}
                  editFinish={() => setEditMode(false)}
                />
              </Block>
            </Expander>
          </Wrap>
        </Col>
        <Col flex="100%">
          <Wrap>
            <Expander
              expand={currentStep === 2}
              onClick={() => {
                setCurrentStep(2);
                setEditMode(true);
              }}
              title={renderShippingInfoBlock()}
              containerStyle={{ marginBottom: 0 }}
              titleStyle={{ textAlign: "center" }}
              containerMobileStyle={{ justifyContent: "center !important" }}
              closable={hasShippingInfo}
            >
              <Block
                title={renderEditTitle("2 運送方式")}
                size={`${constants.fontSize.md}px`}
                fontWeight={700}
                height={"30px"}
                lineHeight={"30px"}
                padding={"0px"}
                bg={constants.colors.background}
                color={constants.colors.text}
                titleMargin={"0 0 16px 0"}
              >
                {/* 根據 deliveryConfig.delivery_type 的值生成對應按鈕 */}
                {shippingInfoExpand ? (
                  <ShippingInfo
                    userConfig={userConfig}
                    deliveryConfig={deliveryConfig}
                    currentShippingType={currentShippingType}
                    setConfig={setConfig}
                    setShippingInfoExpand={setShippingInfoExpand}
                    editFinish={() => setEditMode(false)}
                  />
                ) : (
                  <DeliveryTypeGroup>
                    {constants.supportLogistics.map(type => {
                      const { feeText } = calculateShippingFee(type);

                      return (
                        <DeliveryTypeButton
                          key={type}
                          value={type}
                          onClick={() => {
                            setCurrentShippingType(type);
                          }}
                          className={
                            currentShippingType === type ? "selected" : ""
                          }
                          disabled={type === "ezship"}
                        >
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontWeight: 700,
                              }}
                            >
                              <div>{DELIVERY_TYPE[type]}</div>
                              <span>{`${feeText}`}</span>
                            </div>
                            {/* 除了 self_pick 以外，顯示運費 */}
                            {/* 除了自取，顯示目前選取的運送方式跟資訊 */}
                            {deliveryConfig.delivery_type !== "self_pick" &&
                              deliveryConfig.delivery_type === type && (
                                <InfoBox style={{ marginTop: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <img
                                      src="/images/location_on.svg"
                                      alt="location"
                                      style={{
                                        width: "24px",
                                      }}
                                    />

                                    <RowText
                                      color="#25272c"
                                      value={`${deliveryConfig.receiver_city}${deliveryConfig.receiver_district}${deliveryConfig.receiver_address}`}
                                      containerStyle={{ margin: 0 }}
                                      style={{
                                        width: "100%",
                                        lineHeight: "24px",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <img
                                      src="/images/call.svg"
                                      alt="call"
                                      style={{
                                        width: "24px",
                                      }}
                                    />
                                    <RowText
                                      color="#25272c"
                                      value={`${deliveryConfig.receiver_name} ${deliveryConfig.receiver_phone}`}
                                      containerStyle={{ margin: 0 }}
                                      style={{
                                        width: "100%",
                                        lineHeight: "24px",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </div>
                                  <EditIcon>
                                    <img
                                      src="/images/edit.svg"
                                      alt="edit"
                                      style={{
                                        width: "24px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setShippingInfoExpand(true)
                                      }
                                    />
                                  </EditIcon>{" "}
                                </InfoBox>
                              )}

                            {/* self_pick 被點選的時候要顯示自取地址跟相關資訊 */}
                            {currentShippingType === "self_pick" &&
                              type === "self_pick" && (
                                <InfoBox style={{ marginTop: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <img
                                      src="/images/location_on.svg"
                                      alt="location"
                                      style={{
                                        width: "24px",
                                      }}
                                    />
                                    <RowText
                                      color="#25272c"
                                      value={"新北市中和區板南路496號3樓"}
                                      containerStyle={{ margin: 0 }}
                                      style={{
                                        width: "100%",
                                        lineHeight: "24px",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <img
                                      src="/images/schedule.svg"
                                      alt="schedule"
                                      style={{
                                        width: "24px",
                                      }}
                                    />
                                    <RowText
                                      color="#25272c"
                                      value={
                                        "星期一到五 10:00-18:00 (國定例假日休息) 請先預約取件時間"
                                      }
                                      containerStyle={{ margin: 0 }}
                                      style={{
                                        width: "100%",
                                        lineHeight: "24px",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </div>
                                </InfoBox>
                              )}
                          </div>
                        </DeliveryTypeButton>
                      );
                    })}
                  </DeliveryTypeGroup>
                )}
                <BlockFooter>
                  {!shippingInfoExpand && currentShippingType === "self_pick" && (
                    <StyledButton
                      onClick={() => {
                        setEditMode(false);
                        setConfig("delivery", {
                          ...deliveryConfig,
                          delivery_type: "self_pick",
                        });
                      }}
                      style={{ marginTop: "16px" }}
                    >
                      確定
                    </StyledButton>
                  )}
                  {!shippingInfoExpand &&
                    currentShippingType !== "self_pick" &&
                    deliveryConfig.delivery_type === currentShippingType && (
                      <StyledButton
                        onClick={() => {
                          setEditMode(false);
                          setConfig("delivery", {
                            ...deliveryConfig,
                            delivery_type: currentShippingType,
                          });
                        }}
                        style={{ marginTop: "16px" }}
                      >
                        確定
                      </StyledButton>
                    )}

                  {!shippingInfoExpand &&
                    deliveryConfig.delivery_type !== currentShippingType &&
                    currentShippingType !== "self_pick" && (
                      <StyledButton
                        onClick={() => {
                          setShippingInfoExpand(true);
                          setConfig("delivery", {
                            ...deliveryConfig,
                            delivery_type: "hct",
                          });
                        }}
                        style={{ marginTop: "16px" }}
                      >
                        {currentShippingType === "hct"
                          ? "新增地址"
                          : "新增門市"}
                      </StyledButton>
                    )}
                </BlockFooter>
              </Block>
            </Expander>
          </Wrap>
        </Col>
        <Col flex="100%">
          <Wrap>
            <Expander
              expand={currentStep === 3}
              onClick={() => {
                setCurrentStep(3);
                setEditMode(true);
              }}
              title={renderPaymentInfoBlock()}
              containerStyle={{ marginBottom: 0 }}
              titleStyle={{ textAlign: "center" }}
              containerMobileStyle={{ justifyContent: "center !important" }}
              closable={renderInvoiceContent()}
            >
              <Block
                title={renderEditTitle("3 付款與發票")}
                size={`${constants.fontSize.md}px`}
                fontWeight={700}
                height={"30px"}
                lineHeight={"30px"}
                padding={"0px"}
                bg={constants.colors.background}
                color={constants.colors.text}
                titleMargin={"0 0 16px 0"}
              >
                <MethodsInvoiceContainer>
                  <div>
                    <h3>付款方式</h3>
                    <PaymentMethods
                      paymentValue={paymentTypeObj && paymentTypeObj.value}
                      setParams={setParams}
                    />
                  </div>
                  <div>
                    <h3>發票寄送</h3>
                    <StyledSelectContainer>
                      <StyledSelect
                        size="large"
                        bordered={false}
                        placeholder="選擇發票開立方式"
                        defaultValue={invoiceConfig.invoice_type}
                        onChange={value => {
                          setConfig("invoice", { invoice_type: value });
                        }}
                        options={Object.values(INVOICE_TYPES).map(type => ({
                          value: type.value,
                          label: type.label,
                        }))}
                      />
                      <InvoiceInfo
                        invoiceConfig={invoiceConfig}
                        setConfig={setConfig}
                      />
                    </StyledSelectContainer>
                  </div>
                </MethodsInvoiceContainer>
                <BlockFooter>
                  <StyledButton
                    onClick={() => {
                      setCurrentStep(4);
                    }}
                    style={{ marginTop: "16px" }}
                    disabled={!validatePaymentAndInvoice()}
                  >
                    儲存並繼續
                  </StyledButton>
                </BlockFooter>
              </Block>
            </Expander>
          </Wrap>
        </Col>
        <Col flex="100%">
          <Wrap>
            <Expander
              expand={currentStep === 4}
              onClick={() => setCurrentStep(4)}
              title={renderOrderNoteBlock()}
              containerStyle={{ marginBottom: 0 }}
              titleStyle={{ textAlign: "center" }}
              containerMobileStyle={{ justifyContent: "center !important" }}
              closable={false}
            >
              <Block
                title={renderEditTitle("4 訂單備註")}
                size={`${constants.fontSize.md}px`}
                fontWeight={700}
                height={"30px"}
                lineHeight={"30px"}
                padding={"0px"}
                bg={constants.colors.background}
                color={constants.colors.text}
                titleMargin={"0 0 16px 0"}
              >
                <Input.TextArea
                  style={{
                    height: "124px",
                    border: "solid 1px #DAE0E6",
                    borderRadius: "6px",
                    resize: "none",
                    boxShadow: "none",
                    padding: "12px",
                    marginBottom: "6px",
                  }}
                  placeholder="若有製作、加工、訂單的特別需求，請填寫備註"
                  value={params.note}
                  onChange={e => setParams({ note: e.target.value })}
                />
              </Block>
            </Expander>
          </Wrap>
        </Col>
        <Col flex="100%">
          <Wrap>
            <Block
              title={"商品清單"}
              size={`${constants.fontSize.md}px`}
              fontWeight={700}
              height={"30px"}
              lineHeight={"30px"}
              padding={"0px"}
              bg={constants.colors.background}
              color={constants.colors.text}
              titleMargin={"0 0 16px 0"}
            >
              {/* 根據 cartData 生成商品的清單
              內容要有圖片、商品名稱、數量、價格 */}
              <ItemListContainer>
                {cartData &&
                  cartData.items.map(item => (
                    <ItemContainer key={item.id}>
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: 120 }}
                      />
                      <div
                        style={{
                          fontWeight: 700,
                          color: "#000000",
                          marginTop: 8,
                        }}
                      >
                        {item.name}
                      </div>
                      <div>{`X${item.quantity}`}</div>
                      <div>{`NT$${item.price}`}</div>
                    </ItemContainer>
                  ))}
              </ItemListContainer>
            </Block>
          </Wrap>
        </Col>
      </Row>
    </Fragment>
  );
}

const Wrap = styled.div`
  display: block;
  background-color: ${constants.colors.background};
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 14px;

  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
  }
  ${props => props.css}
`;

const StyledButton = styled(Button)`
  height: 56px;
  font-size: 16px;
  border-radius: 12px;
  text-align: center;
  padding: 16px 24px;
  background-color: ${constants.buttons.background.normal.secondary};
  color: ${constants.buttons.text.normal.secondary};
  &:hover {
    background-color: ${constants.buttons.background.hover.secondary};
    color: ${constants.buttons.text.hover.secondary};
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 16px;
  align-items: flex-start;
  gap: 4px;
  max-width: 100%;
  border-radius: 10px;
  background-color: #f7f8f8;
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #505050;
  }
`;

const BlockFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${constants.colors.highlight};
    border-color: ${constants.colors.highlight};
  }
`;

const DeliveryTypeGroup = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const DeliveryTypeButton = styled(Button)`
  display: block;
  min-height: 100px;
  height: auto;
  border-radius: 10px;
  font-size: 16px;
  padding: 24px;

  &.ant-btn:not([disabled]) {
    &:hover {
      border: none;
      background-color: #edeef1;
    }
  }

  &.selected {
    border: solid 2px #000000;
  }

  &.ant-btn[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    border-color: #d9d9d9;

    &:hover {
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }

  &.ant-btn[disabled].selected {
    border: solid 2px rgba(0, 0, 0, 0.25);
  }
`;

const ShippingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const ItemListContainer = styled.div`
  display: flex;
  gap: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #505050;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;

  img {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 6px;
    object-fit: cover;
  }
`;

const EditIcon = styled.div`
  position: absolute;
  right: 40px;
`;

const MethodsInvoiceContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  position: relative;

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    flex-direction: column;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: ${constants.colors.text};
    margin-bottom: 8px;
  }

  & > div {
    flex: 1;
    gap: 16px;
    padding: 24px;
    border-radius: 10px;
    border: solid 1px #d8dbdf;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  .ant-select.ant-select-in-form-item {
    margin: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: inline-flex;
    align-items: center;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 16px;
  color: #505050;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #b6bac3;

  .ant-select-customize-input {
    padding: 0 16px;
  }

  .ant-select-arrow {
    color: #1c1b1f;
    right: 16px;
  }
`;
