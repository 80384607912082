import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Checkbox } from "antd";
import Input from "../../Widgets/Input";
import AddressField from "./AddressField";
import formatValidator from "../../Utils/formatValidator";

const constants = require("../../constants");

export default function CustomerInfo({ config, setConfig, editFinish }) {
  const { isNotEmpty, isEmail, isMobileNumber } = formatValidator;
  const [saveFrequencyUser, setSaveFrequencyUser] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: config.name,
      phone: config.phone,
      email: config.email,
      address: config.address,
      city: config.city,
      district: config.district,
      zip_code: config.zip,
    });
  }, [form, config]);

  const valid = () => {
    if (
      !isNotEmpty(config.name) ||
      !isNotEmpty(config.phone) ||
      !isNotEmpty(config.email) ||
      !isNotEmpty(config.address) ||
      !isNotEmpty(config.city) ||
      !isNotEmpty(config.district) ||
      !isNotEmpty(config.zip_code)
    ) {
      return false;
    }
    if (!isMobileNumber(config.phone)) {
      return false;
    }
    if (!isEmail(config.email)) {
      return false;
    }
    return true;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="customer-info"
      initialValues={{
        name: config.name,
        phone: config.phone,
        email: config.email,
        address: config.address,
        city: config.city,
        district: config.district,
        zip_code: config.zip,
      }}
    >
      <StyledFormItem label="訂購人姓名" name="name">
        <Input
          placeholder="請輸入證件本名"
          onChange={e => setConfig("user", { ...config, name: e.target.value })}
        />
      </StyledFormItem>
      <StyledFormItem label="手機號碼" name="phone">
        <Input
          placeholder="請輸入手機號碼"
          onChange={e =>
            setConfig("user", { ...config, phone: e.target.value })
          }
        />
      </StyledFormItem>
      <StyledFormItem label="電子郵件" name="email">
        <Input
          placeholder="請輸入電子郵件"
          onChange={e =>
            setConfig("user", { ...config, email: e.target.value })
          }
        />
      </StyledFormItem>
      <StyledFormItem label="通訊地址">
        <AddressField
          zip_code={config.zip}
          city={config.city}
          district={config.district}
          address={config.address}
          onChange={obj => {
            setConfig("user", {
              ...config,
              ...obj,
            });
          }}
        />
      </StyledFormItem>
      <BlockFooter>
        {/* 建立一個 checkbox，可以勾選"儲存為常用訂購人資訊，勾選後會把資訊存到 localStorage */}
        <Checkbox
          style={{ alignSelf: "flex-start" }}
          checked={saveFrequencyUser}
          onChange={e => {
            setSaveFrequencyUser(e.target.checked);
          }}
        >
          儲存為常用訂購人資訊
        </Checkbox>
        <StyledButton
          disabled={!valid()}
          onClick={() => {
            setConfig("user", config);
            editFinish();
          }}
          style={{ marginTop: "16px" }}
        >
          儲存並繼續
        </StyledButton>
      </BlockFooter>
    </Form>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: ${constants.colors.text};
  }

  input {
    display: flex;
    margin: 0;
    height: 48px;
    padding: 12px 16px;
    align-items: center;
    align-self: stretch;
    color: ${constants.colors.text};
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #b6bac3;
  }
`;

const StyledButton = styled(Button)`
  height: 56px;
  font-size: 16px;
  border-radius: 12px;
  text-align: center;
  padding: 16px 24px;
  background-color: ${constants.buttons.background.normal.secondary};
  color: ${constants.buttons.text.normal.secondary};
  &:hover {
    background-color: ${constants.buttons.background.hover.secondary};
    color: ${constants.buttons.text.hover.secondary};
  }
`;

const BlockFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${constants.colors.highlight};
    border-color: ${constants.colors.highlight};
  }
`;
